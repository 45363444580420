/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --wishlist-black-text: #181818;
    --wishlist-border-color: #e5e7eb;
    --button-red-color: #ff0000;
    --button-dark-red-color: #c00;
}

.WishlistPage {
    @include mobile {
        margin-block-start: 0;
    }

    &-Wrapper {
        margin: 2.5rem auto;
        padding: 2.5rem;
        max-width: 1024px;
        min-height: 580px;
        border: 1px solid var(--wishlist-border-color);
        border-radius: 0.75rem;

        @include mobile {
            margin: 2rem 0.5rem;
        }
    }

    &-Header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-block-end: 1.25rem;
        border-bottom: 1px solid var(--wishlist-border-color);
    }

    &-Heading {
        text-transform: uppercase;
        font-size: 24px;
        line-height: 1.56;
    }

    &-ItemCount {
        font-size: 18px;
        line-height: 1.56;
    }

    &-WishListProducts {
        width: 100%;
        margin-inline: auto;
    }

    &-EmptyWishList {
        display: flex;
        padding-block-end: 8rem;
        padding-block-start: 8rem;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-EmptyWishlistHeart {
        display: block;
        width: 160px;
        height: 160px;
        fill: var(--wishlist-border-color);
        cursor: unset;
    }

    &-NoWishlistText {
        font-weight: 700;
        font-size: 28px;
        line-height: 1.33333;
        text-align: center;
        color: var(--wishlist-black-text);

        &_SubHeading {
            font-weight: 400;
            font-size: 20px;
            margin-block-start: 24px;
            max-width: 280px;

            @include after-mobile {
                max-width: 430px;
            }
        }
    }

    &-NoWishlistButtons {
        display: flex;
        margin-block-start: 24px;

        @include mobile {
            flex-direction: column;
        }

        a {
            margin: 0;

            &:nth-child(2) {
                margin: 0 20px;
            }

            @include mobile {
                margin: 0 20px;

                &:nth-child(2) {
                    margin: 20px;
                }
            }
        }
    }

    &-ItemsList {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: max-content;
        grid-column-gap: 1rem;

        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        @include mobile {
            grid-template-columns: 1fr 1fr;
            border: 0;
        }

        @include after-mobile {
            grid-column: 2;
        }
    }

    &-ActionBar {
        display: flex;
        justify-content: end;
        margin-block-end: 1.25rem;

        @include mobile {
            flex-direction: column;
            justify-content: space-between;
        }
    }

    &-AddAllToCartButton,
    &-ShareWishlist,
    &-ClearAllButton {
        margin-inline-start: 10px;
        width: 200px;

        &:not(:last-child) {
            @include mobile {
                margin-block-end: 10px;
            }
        }

        @include mobile {
            width: 100%;
            margin-inline-start: unset;
        }
    }

    &-AddAllToCartButton {
        background: var(--button-red-color);
        border-color: var(--button-red-color);

        &:hover {
            /* stylelint-disable-next-line declaration-no-important */
            background: var(--button-dark-red-color) !important;
            /* stylelint-disable-next-line declaration-no-important */
            border-color: var(--button-dark-red-color) !important;
        }
    }

    &-ShareWishlist {
        border-color: var(--button-background);
        color: var(--button-background);
        background: rgba(0,0,0,0);

        @include after-mobile {
            &:hover {
                background: var(--button-background);
                border-color: var(--color-white);
                color: var(--color-white);
            }
        }
    }
}
