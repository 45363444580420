/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/util';
@import '../../style/abstract/menu-scrollbar';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --wishlist-black-text: #181818;
    --wishlist-border-color: #e5e7eb;
    --wishlist-border-dark-color: #bbbcbd;
    --wishlist-attribute-dark-color: #989b9e;
    --button-red-color: #ff0000;
    --button-dark-red-color: #c00;
}

.WishlistItem {
    &-Wrapper {
        padding-inline-start: 0;
        min-width: 0;

        &::before {
            content: none;
        }

        &:last-child {
            margin-block-end: 6px;
        }

        color: inherit;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-inline: 5px;
        margin-block-start: 10px;
    }

    &-Link {
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &-Picture {
        padding-block-end: 105%;
    }

    &-Name {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        font-size: var(--font-size-medium);
        line-height: 1.0;
        margin-block-end: 5px;

        &_isLoaded {
            text-overflow: ellipsis;
        }

        +.TextPlaceholder {
            overflow: hidden;
        }
    }

    &-Content {
        padding: 0;

        .ProductPrice {
            width: 100%;
            text-transform: uppercase;
            /* stylelint-disable-next-line declaration-no-important */
            margin-block-start: 10px !important;
            /* stylelint-disable-next-line declaration-no-important */
            margin-block-end: 10px !important;
            text-align: center;
            min-height: 15px;

            .ProductPrice-CurrentPrice {
                font-size: var(--font-size-normal);
            }
        }
    }

    &-RemoveButton,
    &-MoveToCart {
        width: 100%;
        margin-block-start: 5px;
        margin-block-end: 5px;
    }

    &-MoveToCart {
        background: var(--button-red-color);
        border-color: var(--button-red-color);
        margin-block-start: 10px;

        &:hover {
            /* stylelint-disable-next-line declaration-no-important */
            background: var(--button-dark-red-color) !important;
            /* stylelint-disable-next-line declaration-no-important */
            border-color: var(--button-dark-red-color) !important;
        }
    }

    &-ItemAttributes {
        display: flex;
        flex-direction: column;
        padding-inline: 25px;
    }

    &-SizeAndQty {
        display: flex;
        justify-content: space-between;
    }

    &-AttributeLabel {
        color: var(--wishlist-attribute-dark-color);
    }

    &-ColorAttribute {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
